

:root {
    --primary-color: #3f3f3f;
    --primary-orange: #EC2842;
    --light-color: white;

    --primary-color-hover: #3f3f3fc9;
    --primary-color-hover-extra: rgb(189, 9, 9);
    --light-color-hover: white;

    --blue-primary: #3498ff;
    --light-green: #36B37E;

    --background: #fff;
    --font: #000;
    --font-dark: #141414;
    --background-layer2: #28282c;


    --colors-grey-100: #E1E1E6;
    --colors-grey-200: #C4C4CC;
    --colors-grey-300: #8D8D99;
    --colors-grey-400: #7C7C8A;
    --colors-grey-500: #505059;
    --colors-grey-600: #323238;
    --colors-grey-700: #29292E;
    --colors-grey-800: #202024;
    --colors-grey-900: #121214;
    --colors-grey-950: #09090A;
    --colors-text-title: #E1E1E6;
    --colors-text-base: #C4C4CC;
    --colors-shape-primary: #202024;

}


.rs-radio-group-picker .rs-radio-checked .rs-radio-checker > label{
    color: var(--primary-orange) !important;
}

.rs-dropdown-item-content > a {
    color: var(--primary-color) !important;;
}

.theme-primary {
    background-color: var(--primary-color) !important;
}

.rs-calendar-table-cell-selected .rs-calendar-table-cell-content{
    background-color: var(--primary-color) !important;
    border-color: var(--primary-color) !important;
}

.rs-picker-has-value .rs-btn .rs-picker-toggle-value, .rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value{
    color: var(--primary-color);
}
.rs-picker-toggle-value:hover{
    border-color: var(--primary-color);
}

.rs-picker-toolbar-right-btn-ok{
    background-color: var(--primary-color) !important;
}


.rs-btn-primary {
    color: #ffffff;
    background-color: var(--primary-color);
}
.rs-btn-primary.rs-btn-icon.rs-btn-icon-with-text > .rs-icon {
    background: var(--primary-color);
}
.rs-btn-primary.rs-btn-loading:hover,
.rs-btn-primary.rs-btn-loading:not(.rs-btn-disabled):hover,
.rs-btn-primary.rs-btn-loading:focus,
.rs-btn-primary.rs-btn-loading:active,
.rs-btn-primary.rs-btn-loading:hover:active {
    color: var(--light-color-hover);
    background-color: var(--primary-color-hover);
}
.rs-btn-primary:focus,
.rs-btn-primary.rs-btn-focus {
    color: #ffffff;
    background-color: var(--primary-color-hover);
}
.rs-btn-primary:focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-btn-primary.rs-btn-focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon {
    background: var(--primary-color-hover);
}
.rs-btn-primary:hover {
    color: var(--light-color-hover);
}
.rs-btn-primary:not(.rs-btn-disabled):hover {
    background-color: var(--primary-color-hover);
}
.rs-btn-primary:not(.rs-btn-disabled):hover.rs-btn-icon.rs-btn-icon-with-text > .rs-icon {
    background: var(--primary-color-hover);
}
.rs-btn-primary:active,
.rs-btn-primary.rs-btn-active,
.rs-open > .rs-dropdown-toggle.rs-btn-primary {
    color: #ffffff;
    background-color: var(--primary-color-hover);
}
.rs-btn-primary:active.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-btn-primary.rs-btn-active.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-open > .rs-dropdown-toggle.rs-btn-primary.rs-btn-icon.rs-btn-icon-with-text > .rs-icon {
    background: var(--primary-color-hover);
}
.rs-btn-primary:active:hover,
.rs-btn-primary.rs-btn-active:hover,
.rs-open > .rs-dropdown-toggle.rs-btn-primary:hover,
.rs-btn-primary:active:focus,
.rs-btn-primary.rs-btn-active:focus,
.rs-open > .rs-dropdown-toggle.rs-btn-primary:focus,
.rs-btn-primary:active.focus,
.rs-btn-primary.rs-btn-active.focus,
.rs-open > .rs-dropdown-toggle.rs-btn-primary.focus {
    color: var(--light-color-hover);
    background-color: var(--primary-color-hover);
}
.rs-btn-primary:active:hover.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-btn-primary.rs-btn-active:hover.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-open > .rs-dropdown-toggle.rs-btn-primary:hover.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-btn-primary:active:focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-btn-primary.rs-btn-active:focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-open > .rs-dropdown-toggle.rs-btn-primary:focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-btn-primary:active.focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-btn-primary.rs-btn-active.focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-open > .rs-dropdown-toggle.rs-btn-primary.focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon {
    background: var(--primary-color-hover-extra);
}
.rs-btn-primary:active,
.rs-btn-primary.rs-btn-active,
.open > .rs-dropdown-toggle.rs-btn-primary {
    background-image: none;
}
.rs-btn-primary.rs-btn-disabled:hover,
.rs-btn-primary.rs-btn-disabled:focus,
.rs-btn-primary.rs-btn-disabled.focus {
    background: var(--primary-color-hover-extra);
}
.rs-btn-primary.rs-btn-loading:hover,
.rs-btn-primary.rs-btn-loading:not(.rs-btn-disabled):hover,
.rs-btn-primary.rs-btn-loading:focus,
.rs-btn-primary.rs-btn-loading:active,
.rs-btn-primary.rs-btn-loading:hover:active {
    color: var(--light-color-hover);
    background-color: var(--primary-color-hover);
}

.rs-btn-ghost {
    color: var(--primary-color) !important;
    border-color: var(--primary-color) !important;
    background-color: transparent;
}

.rs-btn-primary,
.rs-btn-primary > .rs-icon {
    -webkit-transition: color 0.2s linear, background-color 0.3s linear;
    transition: color 0.2s linear, background-color 0.3s linear;
}

.rs-btn-toggle-checked {
    background-color: var(--light-green);
}

.rs-btn-toggle-checked:hover {
background-color: var(--light-green);
}

.rs-pagination > li.rs-pagination-btn-active > a {
    color: var(--primary-color);
    background-color: transparent;
}

.rs-pagination-btn > a{
    background-color: #fff !important;
    border: 1px solid #dee2e6 !important;

color: var(--primary-color) !important;
}

.rs-pagination-btn-disabled{
background-color: rgba(0,0,0,0.2) !important;
}

.rs-pagination-btn-active > a{
background-color:var(--primary-color) !important;
color: white !important; 
}

/* .rs-tag-red {
    background-color: #f443366b !important;
}
.rs-tag-orange {
    background-color: #ff98006b !important;
}
.rs-tag-yellow {
    background-color: #ffca286b !important;
}
.rs-tag-green {
    background-color: #7cff816b !important;
}
.rs-tag-cyan {
    background-color: #00bcd46b !important;
}
.rs-tag-blue {
    background-color: #2196f36b !important;
}
.rs-tag-violet {
    background-color: #673ab76b !important;
} */


/*Chrome*/
@media screen and (-webkit-min-device-pixel-ratio:0) {
    input[type='range'] {
        overflow: hidden;
        -webkit-appearance: none;
        border-radius: 15px;
        background-color: var(--primary-color);
    }
    
    input[type='range']::-webkit-slider-runnable-track {
        height: 10px;
        /* -webkit-appearance: none; */
        color: var(--primary-color);
        margin-top: -1px;
    }
    
    input[type='range']::-webkit-slider-thumb {
        width: 10px;
        -webkit-appearance: none;
        height: 10px;
        cursor: ew-resize;
        background: var(--primary-color-hover-extra);
        box-shadow: -80px 0 0 80px var(--primary-color);
    }

}
/** FF*/
input[type="range"]::-moz-range-progress {
    background-color: var(--primary-color); 
}
input[type="range"]::-moz-range-track {  
    background-color: var(--primary-color);
}
/* IE*/
input[type="range"]::-ms-fill-lower {
    background-color: var(--primary-color); 
}
input[type="range"]::-ms-fill-upper {  
    background-color: var(--primary-color);
}