@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-display: swap;
}

th {
  text-align: left;
}

.table > .card-table-row  { 
  width: 100%;
  background: white;
  margin-top: .7em;
}

.table{
  width: 100%;
}

.toolbar--open {
  z-index: 99999999 !important;
}

.megadraft-block{
  background-color: white;
}

.card-table-row:hover{
  background: #fff !important;
} 

.checkout-product-image{
  width: 100% !important;
  height: 100% !important;
}


@media (max-width: 1024px) {
  .product-title{
    text-overflow: ellipsis;
  }

  .table-container {
    overflow-x: auto;
  }

  .table { 
    /* width: max-content; */
    width: calc(100% + 20rem);
  }

  .table-webhook { 
    /* width: max-content; */
    width: calc(100% + 34rem);
  }

  .rs-sidenav-collapse-out{
    display: none;
    transition: width ease .3s, top ease .3s .3s, transform ease .3s;

  }

  .rs-sidenav-collapse-in{
    position: fixed;
    height: 100%;
    z-index: 999;
    display: block;
    width: 55% !important;
    transition: width ease .3s, top ease .3s .3s, transform ease .3s;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
    font-display: swap;
}


.skeleton-box {
  display: inline-block;
  height: 1em;
  position: relative;
  overflow: hidden;
  background-color: #DDDBDD;
}
.skeleton-box::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translateX(-100%);
  background-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 0.2) 20%, rgba(255, 255, 255, 0.5) 60%, rgba(255, 255, 255, 0));
  animation: shimmer 2s infinite;
  content: "";
}
@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}
/* @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap'); */

body {
  overflow-y: scroll;
}

/* .rs-dropdown-item-content,
.rs-dropdown-item-content:focus,
.rs-dropdown-item-content:active,
.rs-dropdown-item-content:hover{
  background-color: white !important;
} */

#user-avatar {
  background-color: #2e2d2d !important;
}

.rs-sidenav-inverse, .sidenav-container{
  background-color: #2e2d2d !important;
}

.rs-dropdown-menu-collapse-in, .rs-dropdown-menu .rs-dropdown-menu-collapse-in{
  background-color: #3f3f3f !important;
}

.rs-dropdown-toggle:focus,
.rs-dropdown-expand,
a.rs-nav-item-content[tabindex="0"]:hover,
a.rs-dropdown-toggle[tabindex="0"]:hover,
a.rs-nav-item-content[tabindex="0"]:hover{
  background-color: #3f3f3f !important;
}

.rs-container {
  min-height: 60vh;
}

a, a:visited, a:active, a:hover {
  text-decoration: none !important;
}

.grabbable {
  cursor: move; /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -webkit-grab;
}

.rs-dropdown-menu-collapse-in > a > .rs-dropdown-item > .rs-dropdown-item-content,
.rs-dropdown-menu-collapse-in > a > .rs-dropdown-item > .rs-dropdown-item-content:hover,
.rs-dropdown-menu-collapse-in > a > .rs-dropdown-item > .rs-dropdown-item-content:active,
.rs-dropdown-menu-collapse-in > a > .rs-dropdown-item > .rs-dropdown-item-content:visited {
  color: white !important;
} 

body {
  background: rgb(245, 248, 255) !important;
}

.rs-tag {
  border-radius: 100px !important;
}

.rs-footer {
    margin-bottom: 0 !important;
}

.badge-success > .rs-badge-content {   
  background-color: #4caf50;
  top: -5px !important;
  /* left: 0 !important; */
}

.table-head{
    box-sizing: border-box;
    border-width: 0;
    border-style: solid;
    border-color: #d2d6dc;

    text-indent: initial;
    border-spacing: 2px;
    border-bottom-width: 1px;

    border-color: rgba(229, 231, 235, 1);
}

.table-title{
  letter-spacing: 0.05em;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  color: rgba(107, 114, 128, 1);
}

.input-clipboard {
  line-height: 1.25rem;
  font-size: 0.875rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;

  min-width: 12rem;
}

.table-row {
  display: flex;
  align-items: center;
}

.table-row > div {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.upsellAcceptBtn {
  padding:12px 16px;cursor:pointer;color:#FFFFFF;font-weight:600;border-radius:4px;border:1px solid;font-size:20px;
}
.upsellRejectBtn {
  margin-top:1rem;cursor:pointer;font-size:16px;text-decoration:underline;font-family:sans-serif;
}

:-webkit-autofill, :-webkit-autofill:active, :-webkit-autofill:focus, :-webkit-autofill:hover {
  -webkit-transition: all 5000s ease-in-out 0s;
  transition: all 5000s ease-in-out 0s;
  -webkit-transition-property: background-color,color;
  transition-property: background-color,color;
}

* { 
  /* font-family: 'Poppins', sans-serif  */
  font-family: 'Roboto', sans-serif;
  font-display: swap;
}

.clipboardButton:active{
  border-color: rgb(56, 161, 105) !important;
  color: rgb(56, 161, 105) !important;
}

.rs-modal-wrapper{
  margin-top: 5%;
}

.rs-modal-body{
  overflow-x: hidden !important;
}


.orderBump-card {
  width: 100%;
  height: 200px;
  border-radius: 8px;
}

.orderbump-content {
  display: flex;
  flex-direction: row;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding: 0px 28px 10px 20px;
}

.orderbump-title{
  width: 75%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

/* mobile */
@media (max-width: 1024px) {
  .orderbump-content {
    display: flex;
    flex-direction: column;
  }
  
  .orderbump-title{
    width: 100% !important;
  }

  .orderBump-card-title{
    font-size: 16px !important;
  }
}

.orderbump-tag {
  margin-left: 20px;
  font-weight: 600;
  width: -webkit-fit-content !important;
  width: -moz-fit-content !important;
  width: fit-content !important;
}

.orderBump-card-title{
  font-size: 1.3em;
  color: white;
  text-shadow: -1px 4px 19px black;
}


.orderBump-border {
  border-color: rgb(160, 174, 192);
  color: rgb(113, 128, 159);
  border-style: dashed;
  border-radius: 0.25rem;
  border-width: 4px;
  min-height: 25px;
  /* padding: 5px; */
}

.orderBump-header {
  font-size: 18px;
  color: rgb(49, 130, 206);
  background-color: rgb(226, 232, 240);

  font-weight: 700;
  text-align: center;
  text-transform: uppercase;

  padding: 0.5rem;

  width: 100%;
  min-height: 30px;
}

.orderBump-body{
  box-sizing: border-box;
  background: rgba(244, 245, 247, 1);

  display: flex;
  flex-direction: row;
  align-items: center;
}

.orderBump-arrow{
  margin-left: 10px;
}

.orderBump-offer{
  background: white;
  padding-left: 5px;
  margin-left: 5px;
  margin-right: 10px;
  width: 100%;
  min-height: 70px;
  margin-top: 25px;
  margin-bottom: 25px;

  box-shadow: 0 10px 15px -3px rgb(0 0 0 / 10%), 0 4px 6px -2px rgb(0 0 0 / 5%);

  display: flex;
  align-items: center;
}

.orderBump-offer {
  font-size: 19px;
  font-weight: 700;
}



.loading-payment {
  background-color: rgba(0, 0, 0, 0.4);
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 10000;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  text-align: center;

  color: #fff;

}


.page-row {
  height: auto;
}

.card-saved-container {
  border: 1px solid rgba(0, 0, 0, 0.137);
  padding: 1.2rem;
  outline: none;
  max-width: 70%;
  min-width: unset;
  border-radius: 5px;
  background-color: rgba(221, 228, 236, 0.301) !important;
  border-color: rgba(221, 228, 236, 0.459) !important;
  /* margin: 2vh 0; */
  margin-top: 10px !important;
  overflow: hidden;
}

.credit-card-last4 {
  font-family: "PT Mono", Helvetica, sans-serif;
  font-display: swap;
  font-size: 18px;
}

.credit-card-last4:before {
  content: "**** **** **** ";
  color: #4f4d4d;
  font-size: 18px;

  position: -webkit-sticky;

  position: sticky;
  top: 35px;
}


.nav-logo-container{
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 15px;
}

.rs-sidenav-body{
  position: relative;
  margin-top: 25px;
}

.blue-panel{
  background-color: #EC2842;
  height: 100vh;
  float: left !important;
}

.white-panel{
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.nav-dashboard-container{
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
}

.flex-row-v-end{
  display: flex;
  width: 100%;
  justify-content: flex-end;
}

.bigCard{
  /* min-height: 350px; */
  /* max-height: 400px; */
  height: 325px;
}

.bigCard > .rs-panel-body {
  height: 100%;
}

.notificationCard:hover{
  background-color: rgb(245, 248, 255);
}
.notificationBody{
  font-size: .9em;
  font-weight: 300;
  color: rgba(0, 0, 0, 0.4);
}

.userAvatarContainer{
  margin-right: 1% !important;
}

@media (max-height: 900px) {
  .blue-panel{
    height: 160vh;
  }
  
}
/* ultra wide */
@media (min-width: 2300px) {
  .svg-model{
    width: 45%;
  }
  
  .login-side-area {
    width: 30% !important;
  }
}


.logo-container {
  text-align: left;
  margin: 2em 10em 2em 10em;
}

@media (min-height: 900px) {
  .login-container{
    /* padding-top: 10em; */
  }
}

.login-area  {
  width: 60%;
}

.login-side-area{
  width: 50%;
}

.login-container{
  margin: 0em 8em 3em 8em;
  width: 60%;
  min-height: unset !important;
  /* padding-top: 10em; */
}

.register-container{
  /* margin: 0em 40em 3em 40em; */
  top: 30%;

  display: flex !important;
  justify-content: center !important;
}

.register-card-container{
  /* width: 40%; */
}

.align-end-v-middle {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.align-center-v-middle {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-row-v-middle {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.flex-row-v-middle-between {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.flex-column-v-middle {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.card-bordered {
  border: 2px solid #1d8dfc91 !important;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.dot {
  border-radius: 50%;
  display: inline-block;
  height: 10px;
  width: 10px;
}

.text-white-center{
  color: white;
  text-align: center;
}

.title {
  font-weight: 500 !important;
  font-size: 1.9rem;
  margin-bottom: .6em;
}

.subtitle {
  color: #615585;
  font-weight: 400;
  font-size: 1.2rem;
}

.label{
  font-size: 1rem;
}

.label-senha{
  display: flex;
  justify-content: space-between;
}

.menu-icon-open{
  color: #EC2842;
}

.btn-dark,
.btn-dark:hover,
.btn-dark:not(.rs-btn-disabled):hover,
.btn-dark:focus,
.btn-dark:active,
.btn-dark:hover:active {

  color: #fff !important;
  background-color: #464646 !important;
}

.dashboard-container{
  display: flex;
  flex-direction: row;
  min-height: 100vh;
}

@media (min-height: 880px){
  .dashboard-container{
    min-height: 100vh;
  }
}

@media (max-width: 1024px) {
  .sidenav-container{
    position: relative !important;
  }

}

.default-position{
  width: 55.99px
}

.sidenav-container{
  /* position: fixed; */
  background-color: #262626;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  position: fixed;

  z-index: 900 !important;
}

.rs-drawer-title{
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: flex-start;
}

.rs-drawer-title > button {
  padding-left: 0 !important;
}

.welcome{
  font-size: 1.8em;
  width: 100%;
}

.holder{
  margin-top: unset !important;
  width: 100%;
  font-size: .9em;
  font-weight: 300;
}

.cardTitle{
  font-weight: 500;
  color: rgba(0,0,0,0.5);
}

.iconCinza {
  color: rgba(0,0,0,0.5);
}

.content{
  margin: auto;
  /* margin-left: 60px; */
  padding: 50px 6em 6em;
  width: 1600px;
  max-width: 1600px;
}

.content-menu-expanded {
  padding: 50px 150px 150px;
}

.card-table-head{
  font-size: 0.75rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 600;
}

.card-table-row{
  border-radius: 0.375rem;
  box-shadow: 0 0.75rem 1.5rem rgb(18 38 63 / 3%);
  padding: 0.40rem;
}

.list-responsive {
  padding: 20px;

  display: flex;
  flex-direction: row;
  align-items: center;
}

.card-table-row:hover{
  background-color:rgb(49, 130, 206, 0.3);
}

#ace-editor * {
  font-family: inherit;
}

#ace-editor { 
  font-family: "Roboto Mono", monospace !important;
  font-size: 12px !important;
  font-weight: 400 !important;
  letter-spacing: 0 !important;
  width: 100% !important;
}

.pointer{
  cursor: pointer !important;
}

.pointer:hover {
  color: #1d8dfc;
  background-color:rgba(0, 0, 0, 0.1);
}

.thumb-button {
  cursor: pointer;
  padding: 0.7rem;
  border-radius: 99999999px;
  background: rgba(0,0,0,0.5);

  width: 2.5rem;
  display: flex;
  justify-content: center;
}

.thumb-button:hover {
  background: rgba(0,0,0,0.9);
}

.status-blue {
  border-radius: 50px !important;
  color: #449afc !important;
  background-color: #0077ff7c !important;
  border-color: #0077ff7c !important;
  box-shadow: inset 0 1px 0 rgb(255 255 255 / 15%) !important;
}
.status-blue:hover{
  border-radius: 50px !important;
  color: #0077ff !important;
  background-color: #0077ff !important;
  border-color: white !important;
  box-shadow: inset 0 1px 0 rgb(255 255 255 / 15%) !important;
}

.payment-status-pending {
  border-radius: 50px !important;
  color: #FFAB00 !important;
  background-color: #ffeecc !important;
  border-color: #ffeecc !important;
  box-shadow: inset 0 1px 0 rgb(255 255 255 / 15%) !important;
}

.payment-status-pending:hover{
  border-radius: 50px !important;
  background-color: #FFAB00 !important;
  border-color: #FFAB00 !important;
  color: white !important;
  box-shadow: inset 0 1px 0 rgb(255 255 255 / 15%) !important;
}

.payment-status-approved {
  border-radius: 50px !important;
  color: #36B37E !important;
  background-color: #d7f0e5 !important;
  border-color: #d7f0e5 !important;
  box-shadow: inset 0 1px 0 rgb(255 255 255 / 15%) !important;
}

.payment-status-approved:hover {
  border-radius: 50px !important;
  color: white !important;
  background-color: #36B37E !important;
  border-color: #36B37E !important;
  box-shadow: inset 0 1px 0 rgb(255 255 255 / 15%) !important;
}

.payment-status-denied {
  border-radius: 50px !important;
  color: #FF5630 !important;
  background-color: #ffddd6 !important;
  border-color: #ffddd6 !important;
  box-shadow: inset 0 1px 0 rgb(255 255 255 / 15%) !important;
}
.payment-status-denied:hover {
  border-radius: 50px !important;
  color: white !important;
  background-color: #FF5630 !important;
  border-color: #FF5630 !important;
  box-shadow: inset 0 1px 0 rgb(255 255 255 / 15%) !important;
}

.payment-status-refunded {
  border-radius: 50px !important;
  color: #fff !important;
  background-color: #0c0c0c40 !important;
  border-color: #0c0c0c40 !important;
  box-shadow: inset 0 1px 0 rgb(255 255 255 / 15%) !important;
}
.payment-status-refunded:hover {
  border-radius: 50px !important;
  color: white !important;
  background-color: #0c0c0c70 !important;
  border-color: #0c0c0c70 !important;
  box-shadow: inset 0 1px 0 rgb(255 255 255 / 15%) !important;
}

.message-info-green{
  border-radius: 6px !important;
  color: #36B37E !important;
  background-color: #d7f0e5 !important;
  border-color: #d7f0e5 !important;
  box-shadow: inset 0 1px 0 rgb(255 255 255 / 15%) !important;
}

.rs-sidenav{ 
  transition: all 0s ease-in-out 0s !important;
}

.photoUploader{
  width: 100%;
  height: 180px;
  border-color: #ccc;
  border-style: dashed;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor:pointer;
  margin: 50px 15px !important;
  padding-top: 150px;
}

.photoUploader > div > p {
  padding-top: 5%;
  font-size: 20px;
}

.rs-drawer-body{
  overflow-x: hidden !important;
  /* width: 86% !important; */
  max-height: 80vh !important;
}

.rs-message-error {
  /* background-color: #f5c6cb !important;
  color: #721c24 !important;  */
}

.svg-image-recovery-passwd{
  top: 20vh;
  position: absolute;
}

.card-position-recovery-passwd{
  top: -10vh;
}

.checkout-header{
  padding-top: 20px;
}

.shadow-md {
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgb(0 0 0 / 6%);
}

.shadow-md-selected {
  box-shadow: 0 4px 6px -1px #349aff57, 0 2px 4px -1px rgb(0 0 0 / 6%);
}

.checkout-container{
  margin: 0 150px !important;
}

.payment-method-option{
  border-radius: 3px;
  background: white;
  padding: 25px 15px;
  height: 80px;
  width: 100%;
  display: flex;
  align-items: center;

  display: flex;
  justify-content: center;
  align-items: center;
}

.payment-method-selected{
  border: 1px solid #349aff57;
  color: #3498ff;

  transition: border .5s;

}

.shipping-method-option{
  border-radius: 3px;
  background: white;
  padding: 25px 15px;
  height: 80px;
  width: 100%;
  display: flex;
  align-items: center;

  display: flex;
  justify-content: center;
  align-items: center;

  /* border: 1px solid #27272757; */
  /* color: #262626; */

}

.shipping-method-option > .rs-radio-checker > label > .rs-radio-wrapper {
  top: 20px !important;
}

.shipping-shadow-md-selected {
  box-shadow: 0 4px 6px -1px #349aff57, 0 2px 4px -1px rgb(0 0 0 / 6%);
}

.shipping-logo {
  width: 150%;
}


.shipping-method-selected{
  border: 1px solid #349aff57;
  color: #3498ff;

  transition: border .5s;

}

.rs-panel, .rs-panel-body {
  position: relative !important;
}


#payment-grid > .rs-flex-box-grid-item-3 {
  width: unset !important;
}

.only-mobile {
  display: none !important;
}

.card-credit-brands > .rs-panel-body {
  padding: 1px 10px !important;

  display: flex;
  justify-content: center;
  align-items: center;
}

.credit-card-title {
  margin-bottom: 6px;
  padding-left: 3px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

@media (min-width: 1790px) {
  .card-checkout{
    margin-right: 25px
  }
  .checkout-container{
    margin: 0 450px !important;
  }
  .checkout-header{
    padding: 20px 290px 25px 450px;
  }

  /* .payment-method-option{
    width: 250px;
  } */
}

.img-nothing{
  width: 80%;
}

.expanded{
  width: 230px !important;
}

@media (min-width: 1025px) {
  .profile-avatar { 
    background: #EC2842 !important;
  }
}

.table-links{
  padding:"10px 15px";
}

.divider{
  width: 100%;
  border-top: 1px solid #0c0c0c20;
  padding-top: 10px;
  padding-bottom: 10px;
}



@media (max-width: 1024px) {
  .shipping-logo {
    width: 100px;
  }
  .table-coproduction {
    width: calc(100% + 40rem) !important;
  }
  .productSectionTitle{
    margin-top: 25px;
  }
  .productSectionDescription{
    text-align: justify;
  }
  .paymentMethodSpaceing{
    margin-bottom: 10px;
  }
  .table-links{
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
  }
  .table-links > div {
    padding-bottom: 10px;
  }
  .input-clipboard{
    margin-right: 2%;
  }
  .col-flex-row{
    display: flex;
    flex-direction: row;
  }
  .expanded{
    width: unset !important;
  }

  .mobile-footer-item{
    margin-bottom: 25px !important;
  }

  .mobile-direction-column{
    flex-direction: column !important;
  }

  .mobile-fix-row-spacing{
    margin-top: 20px !important;
  }

  .mobile-text-justify{
    margin-top: 25px;
    text-align: justify !important;
  }

  .profile-avatar { 
    background: #d9d9d9 !important;
  }

  .rs-nav-default{
    background: #262626 !important;
    padding-bottom: 10px;
    padding-top: 10px;
  }

  .menu-icon-open{
    color: white;
  }

  .moneyIcon{
    margin-right: 20px;
    margin-left: 10px;
  }

  .rs-container{
    min-height: unset;
  }

  .blue-panel{
    height: 240px !important;
  }

  .text-white-center{
    /* margin-top: 95px; */
    font-size: 30px;
  }

  .img-nothing{
    width: 100%;
  }

  .card-saved-container{
    max-width: unset !important;
  }

  .credit-card-last4 {
    font-family: "PT Mono", Helvetica, sans-serif;
    font-display: swap;
    font-size: 12px;
  }
  
  .credit-card-last4:before {
    content: "**** **** ";
    color: #4f4d4d;
    font-size: 12px;
  
    position: -webkit-sticky;
  
    position: sticky;
    top: 35px;
  }  

  .userAvatarContainer{
    margin-right: 3% !important;
  }

  .mobile-w-full{
    width: 100%;
  }

  .mobile-overflow-auto{
    overflow-x: auto;
  }

  .mobile-w-fit{
    width: -webkit-max-content;
    width: max-content;
  }

  .rs-drawer-body{
    height: unset !important;
  }

  .credit-card-title {
    flex-direction: column-reverse;
    align-items: flex-start;
  }

  .credit-card-title > div {
    margin-bottom: 15px;
  }


  .checkout-container > .rs-row{
    display: flex;
    flex-direction: column-reverse;
  }

  .formHeader{
    margin-top: 25px;
  }
  .notFoundSvg{
    width: 100%;
    margin-top: 20%;
  }
  .notFoundText{
    text-align: center;
  }
  .only-mobile {
    display: block !important;
  }
  .only-desk {
    display: none !important;
  }

  .align-xs-center{
    text-align: center;
  }

  .checkout-header {
    padding: 20px 25px 25px 15px;
  }

  .checkout-container {
    margin: 0 20px !important;
  }
  

  .welcome-message{
    margin-left: unset;
    align-items: center;
    width: 100%;
  }

  .welcome{
    font-size: 1.3em;
  }

  .svg-model{
    display: none;
  }

  .blue-panel{
    height: 50vh;
    position: relative !important;
  }

  .logo-container {
    text-align: left;
    margin: 2em 2em 2em 2em;
  }

  .login-side-area{
    width: 90%;
  }

  .login-container{
    margin: 0;
    width: 100%;
    padding-top: unset;
  }

  .register-container{
    margin: 3em;
    width: 80%;
  }

  .content {
    padding: 50px 15px 15px;
    width: 100%;
  }

  .bigCard{
    min-height: unset;
  }

  .rs-modal{
    width: 100% !important;
    margin-top: 0 !important;
  }

  .rs-modal-wrapper{
    margin-top: 0 !important;
  }

  .rs-modal-content{
    border-radius: 0 !important;
    height: 100vh !important;
  }

  

  .rs-modal-body{
    margin-top: 20px;
    max-height: 85% !important;
    height: 85% !important;
  }

  .rs-drawer{
    width: 100% !important;
  }

  .rs-drawer-body{
    width: 90% !important;
    margin: 15px 22px !important;
  }

  .svg-image-recovery-passwd{
    top: 10vh;
    position: absolute;
  }

  .rs-icon-size-5x, .rs-icon-size-5x.rs-icon {
    color: #EC2842 !important;
  }

  .card-position-recovery-passwd{
    top: -20vh;
  }
}




/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}


/* animation */


.animation-ctn{
  text-align:center;
  margin-top:5em;
}

@keyframes checkmark {
    0% {
        stroke-dashoffset: 100px
    }

    100% {
        stroke-dashoffset: 0px
    }
}

@keyframes checkmark-circle {
    0% {
        stroke-dashoffset: 480px 
    }

    100% {
        stroke-dashoffset: 960px
    }
}

@keyframes colored-circle { 
    0% {
        opacity:0
    }

    100% {
        opacity:100
    }
}

/* other styles */
/* .svg svg {
    display: none
}
 */
.inlinesvg .svg svg {
    display: inline
}

/* .svg img {
    display: none
} */

.icon--order-success svg polyline {
    animation: checkmark 0.25s ease-in-out 0.7s backwards
}

.icon--order-success svg circle {
    animation: checkmark-circle 0.6s ease-in-out backwards;
}
.icon--order-success svg circle#colored {
    animation: colored-circle 0.6s ease-in-out 0.7s backwards;
} 

/* end animation */

.thumbnail-preview {
  width: 200px;
  height: 100px;
  border: 1px solid #ccc;
  background-color: #f5f5f5;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  display: flex;
  justify-content: center;
  align-items: center;
}

.indeterminate-progress-bar {
  /* Color */
  background-color: #d1d5db;

  /* Rounded border */
  border-radius: 9999px;

  /* Size */
  height: 0.5rem;

  position: relative;
  overflow: hidden;
}

.indeterminate-progress-bar__progress {
  /* Color */
  background-color: #3b82f6;

  /* Rounded border */
  border-radius: 9999px;

  /* Absolute position */
  position: absolute;
  bottom: 0;
  top: 0;
  width: 50%;

  /* Move the bar infinitely */
  animation-duration: 1.6s;
  animation-iteration-count: infinite;
  animation-name: indeterminate-progress-bar;
}

@keyframes indeterminate-progress-bar {
  from {
      left: -50%;
  }
  to {
      left: 100%;
  }
}

.draggable-item-lesson:hover{
  background-color: rgba(0,0,0,.04) !important;
}


.block-loader {
  width: 100%;
  position: absolute;
  top: 0;
  height: 100%;
  z-index: 9999;
  background: rgba(0,0,0,0.1);
  display: flex;
  justify-content: center;
  align-items: center;
}

.rs-dropdown-menu{
  z-index: 999 !important;
}

.weekDays_Container{
  display: flex;
  justify-content: space-between;
}


.weekDays {
  width: 14.28%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  border: 1px solid #e5e5ea;
  padding-top: 10px;
  border-right: unset;
  padding-bottom: 5px;
}

.weekDays_parent {
  border-left: unset !important;
}

.weekDays_first{
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
.weekDays_last{
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border-right: 1px solid #e5e5ea;

}

/* calendar */


.rdp{
  --rdp-cell-size: 1.6em !important;
  margin: 1em 0 !important; 
  display: flex;
  justify-content: center;
}

.calendar-selected:not([disabled]) { 
  font-weight: bold; 
  border: 2px solid currentColor;
}
.calendar-selected:hover:not([disabled]) { 
  border-color: #1675e0;
  color: #1675e0;
}

.calendar-selected{
  border-color: #1675e0;
  color: #1675e0;
}

.calendar-today { 
  /* font-weight: bold;
  font-size: 140%; 
  color: red; */
}

.tag-list > .rs-tag{
  margin-top: 0 !important;
}

.rs-popover{
  width: auto !important;
}

.card-tags {
  display: flex;
  flex-wrap: wrap;
  grid-column-gap: 3px;
  column-gap: 3px;
  grid-row-gap: 3px;
  row-gap: 3px;
  width: 95%;
}
.rs-steps-item-status-process .rs-steps-item-icon-wrapper:not(.rs-steps-item-custom-icon) {
  border-color: #EC2842 !important;
    background: #EC2842 !important;
    color: #fff;
}

.rs-icon-size-5x, .rs-icon-size-5x.rs-icon {
  color: #EC2842 !important;
}
.lds-ripple {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}

.lds-ripple div {
    position: absolute;
    border: 4px solid #fff;
    opacity: 1;
    border-radius: 50%;
    animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.lds-ripple div:nth-child(2) {
    animation-delay: -1s;
}

@keyframes lds-ripple {
    0% {
        top: 36px;
        left: 36px;
        width: 0;
        height: 0;
        opacity: 0;
    }

    4.9% {
        top: 36px;
        left: 36px;
        width: 0;
        height: 0;
        opacity: 0;
    }

    5% {
        top: 36px;
        left: 36px;
        width: 0;
        height: 0;
        opacity: 1;
    }

    100% {
        top: 0px;
        left: 0px;
        width: 72px;
        height: 72px;
        opacity: 0;
    }
}


:root {
    --primary-color: #3f3f3f;
    --primary-orange: #EC2842;
    --light-color: white;

    --primary-color-hover: #3f3f3fc9;
    --primary-color-hover-extra: rgb(189, 9, 9);
    --light-color-hover: white;

    --blue-primary: #3498ff;
    --light-green: #36B37E;

    --background: #fff;
    --font: #000;
    --font-dark: #141414;
    --background-layer2: #28282c;


    --colors-grey-100: #E1E1E6;
    --colors-grey-200: #C4C4CC;
    --colors-grey-300: #8D8D99;
    --colors-grey-400: #7C7C8A;
    --colors-grey-500: #505059;
    --colors-grey-600: #323238;
    --colors-grey-700: #29292E;
    --colors-grey-800: #202024;
    --colors-grey-900: #121214;
    --colors-grey-950: #09090A;
    --colors-text-title: #E1E1E6;
    --colors-text-base: #C4C4CC;
    --colors-shape-primary: #202024;

}


.rs-radio-group-picker .rs-radio-checked .rs-radio-checker > label{
    color: var(--primary-orange) !important;
}

.rs-dropdown-item-content > a {
    color: var(--primary-color) !important;;
}

.theme-primary {
    background-color: var(--primary-color) !important;
}

.rs-calendar-table-cell-selected .rs-calendar-table-cell-content{
    background-color: var(--primary-color) !important;
    border-color: var(--primary-color) !important;
}

.rs-picker-has-value .rs-btn .rs-picker-toggle-value, .rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value{
    color: var(--primary-color);
}
.rs-picker-toggle-value:hover{
    border-color: var(--primary-color);
}

.rs-picker-toolbar-right-btn-ok{
    background-color: var(--primary-color) !important;
}


.rs-btn-primary {
    color: #ffffff;
    background-color: var(--primary-color);
}
.rs-btn-primary.rs-btn-icon.rs-btn-icon-with-text > .rs-icon {
    background: var(--primary-color);
}
.rs-btn-primary.rs-btn-loading:hover,
.rs-btn-primary.rs-btn-loading:not(.rs-btn-disabled):hover,
.rs-btn-primary.rs-btn-loading:focus,
.rs-btn-primary.rs-btn-loading:active,
.rs-btn-primary.rs-btn-loading:hover:active {
    color: var(--light-color-hover);
    background-color: var(--primary-color-hover);
}
.rs-btn-primary:focus,
.rs-btn-primary.rs-btn-focus {
    color: #ffffff;
    background-color: var(--primary-color-hover);
}
.rs-btn-primary:focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-btn-primary.rs-btn-focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon {
    background: var(--primary-color-hover);
}
.rs-btn-primary:hover {
    color: var(--light-color-hover);
}
.rs-btn-primary:not(.rs-btn-disabled):hover {
    background-color: var(--primary-color-hover);
}
.rs-btn-primary:not(.rs-btn-disabled):hover.rs-btn-icon.rs-btn-icon-with-text > .rs-icon {
    background: var(--primary-color-hover);
}
.rs-btn-primary:active,
.rs-btn-primary.rs-btn-active,
.rs-open > .rs-dropdown-toggle.rs-btn-primary {
    color: #ffffff;
    background-color: var(--primary-color-hover);
}
.rs-btn-primary:active.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-btn-primary.rs-btn-active.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-open > .rs-dropdown-toggle.rs-btn-primary.rs-btn-icon.rs-btn-icon-with-text > .rs-icon {
    background: var(--primary-color-hover);
}
.rs-btn-primary:active:hover,
.rs-btn-primary.rs-btn-active:hover,
.rs-open > .rs-dropdown-toggle.rs-btn-primary:hover,
.rs-btn-primary:active:focus,
.rs-btn-primary.rs-btn-active:focus,
.rs-open > .rs-dropdown-toggle.rs-btn-primary:focus,
.rs-btn-primary:active.focus,
.rs-btn-primary.rs-btn-active.focus,
.rs-open > .rs-dropdown-toggle.rs-btn-primary.focus {
    color: var(--light-color-hover);
    background-color: var(--primary-color-hover);
}
.rs-btn-primary:active:hover.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-btn-primary.rs-btn-active:hover.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-open > .rs-dropdown-toggle.rs-btn-primary:hover.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-btn-primary:active:focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-btn-primary.rs-btn-active:focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-open > .rs-dropdown-toggle.rs-btn-primary:focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-btn-primary:active.focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-btn-primary.rs-btn-active.focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-open > .rs-dropdown-toggle.rs-btn-primary.focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon {
    background: var(--primary-color-hover-extra);
}
.rs-btn-primary:active,
.rs-btn-primary.rs-btn-active,
.open > .rs-dropdown-toggle.rs-btn-primary {
    background-image: none;
}
.rs-btn-primary.rs-btn-disabled:hover,
.rs-btn-primary.rs-btn-disabled:focus,
.rs-btn-primary.rs-btn-disabled.focus {
    background: var(--primary-color-hover-extra);
}
.rs-btn-primary.rs-btn-loading:hover,
.rs-btn-primary.rs-btn-loading:not(.rs-btn-disabled):hover,
.rs-btn-primary.rs-btn-loading:focus,
.rs-btn-primary.rs-btn-loading:active,
.rs-btn-primary.rs-btn-loading:hover:active {
    color: var(--light-color-hover);
    background-color: var(--primary-color-hover);
}

.rs-btn-ghost {
    color: var(--primary-color) !important;
    border-color: var(--primary-color) !important;
    background-color: transparent;
}

.rs-btn-primary,
.rs-btn-primary > .rs-icon {
    transition: color 0.2s linear, background-color 0.3s linear;
}

.rs-btn-toggle-checked {
    background-color: var(--light-green);
}

.rs-btn-toggle-checked:hover {
background-color: var(--light-green);
}

.rs-pagination > li.rs-pagination-btn-active > a {
    color: var(--primary-color);
    background-color: transparent;
}

.rs-pagination-btn > a{
    background-color: #fff !important;
    border: 1px solid #dee2e6 !important;

color: var(--primary-color) !important;
}

.rs-pagination-btn-disabled{
background-color: rgba(0,0,0,0.2) !important;
}

.rs-pagination-btn-active > a{
background-color:var(--primary-color) !important;
color: white !important; 
}

/* .rs-tag-red {
    background-color: #f443366b !important;
}
.rs-tag-orange {
    background-color: #ff98006b !important;
}
.rs-tag-yellow {
    background-color: #ffca286b !important;
}
.rs-tag-green {
    background-color: #7cff816b !important;
}
.rs-tag-cyan {
    background-color: #00bcd46b !important;
}
.rs-tag-blue {
    background-color: #2196f36b !important;
}
.rs-tag-violet {
    background-color: #673ab76b !important;
} */


/*Chrome*/
@media screen and (-webkit-min-device-pixel-ratio:0) {
    input[type='range'] {
        overflow: hidden;
        -webkit-appearance: none;
        border-radius: 15px;
        background-color: var(--primary-color);
    }
    
    input[type='range']::-webkit-slider-runnable-track {
        height: 10px;
        /* -webkit-appearance: none; */
        color: var(--primary-color);
        margin-top: -1px;
    }
    
    input[type='range']::-webkit-slider-thumb {
        width: 10px;
        -webkit-appearance: none;
        height: 10px;
        cursor: ew-resize;
        background: var(--primary-color-hover-extra);
        box-shadow: -80px 0 0 80px var(--primary-color);
    }

}
/** FF*/
input[type="range"]::-moz-range-progress {
    background-color: var(--primary-color); 
}
input[type="range"]::-moz-range-track {  
    background-color: var(--primary-color);
}
/* IE*/
input[type="range"]::-ms-fill-lower {
    background-color: var(--primary-color); 
}
input[type="range"]::-ms-fill-upper {  
    background-color: var(--primary-color);
}
